<template>
    <v-container fluid class="ma-0 pa-0">
        <AcordosTabs :fornecedor_global="fornecedor_global" />
        <v-row style="min-height: calc(100% - 50px)">
            <v-col cols="12" class="mt-0 pt-0">
                <template>
                    <router-view
                        @fornecedor_resumo="setFornecedorResumo($event)"
                    ></router-view>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AcordosTabs from "./_components/acordos_tabs.vue";
export default {
    components: {
        AcordosTabs,
    },
    data() {
        return {
            fornecedor_global: null,
        };
    },
    methods: {
        goTo(rota) {
            this.$router.push({ name: rota });
        },
        setFornecedorResumo(fornecedor) {
            this.fornecedor_global = fornecedor;
        },
    },
    watch: {
        $route(to, from) {
            if (to.name === "acordo")
                this.$router.push({
                    name: "acordoPdv",
                    params: { fornecedor_global: this.fornecedor_global },
                });
        },
    },

    created() {
        console.log(this.$router.name);
        if (!this.$route.name || this.$route.name == "acordo") {
            this.$router.push({
                name: "acordoPdv",
                params: { fornecedor_global: this.fornecedor_global },
            });
        }
    },
};
</script>

<style>
</style>
